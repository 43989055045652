import React, {useState} from 'react'

export default function TextForm(props) {
    const handleUpConevert =() =>{
        let newTxt = text.toUpperCase();
        setText(newTxt);
    }
    const handlelwConvert =()=>{
        let newTxt = text.toLowerCase();
        setText(newTxt);
    }
    const handleSpConvert =()=>{
        let newTxt = text.trim();
        setText(newTxt);
    }
        const handeonClick =(event) =>{
            setText(event.target.value);
        }

    const [text,setText] =useState('Enter Text Here!!');

    return (
    <div>
    <div className="mb-3">
        <h3>{props.heading}</h3>
        <textarea className="form-control" value={text} onChange={handeonClick} id="form1" rows="3"></textarea>
    </div>
    <button className="btn btn-primary" onClick={handleUpConevert}>Conevrt to Upper Case</button>
    <button className="btn btn-warning mx-2" onClick={handlelwConvert}>Converrt to Lower Case</button>
    <button className="btn btn-success" onClick={handleSpConvert}>Remove Space</button>
    </div>
  )
}
