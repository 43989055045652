import "./App.css";
import Navbar from "./component/Navbar";
import TextForm from "./component/TextForm";

function App() {
  return (
    <>
      <Navbar title="Sarvarays"/>
      <div className="container my-4">
      <TextForm heading="Type any thing you want to convert to upper case!!"/>
      
      </div>
      
    </>
  );
}

export default App;
